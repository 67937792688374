
.post-statistic-response{
  .card{
    .card-body{
      .table-responsive{
        table{
          .table-header{
            tr>th{
              &:nth-child(2){
                width: 10%;
              }
              &:nth-child(6){
                width: 15%;
              }
            }
          }
        } 
      }
    }
  }
}