.k-file-picker{
  width: 100%;
  //overflow: hidden;
  .k-file-input{
    background-color: #FFF;
    display: flex;
    padding: 7px 16px;
    margin-bottom: 7px;
    input{
      outline: none;
      border: 0;
      width: 100%;
    }
    button{
      border: 0;
      background-color: #FFF;
      white-space: nowrap;
      font-weight: bold;
      &:hover{
        color: $primary-color;
      }
    }
  }

  .k-image-preview{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .preview{
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      //background-color: #b6b6b6;
      img{
        object-fit: contain;
        width: 100%;
        height: 100%;
        &.cover{
          object-fit: cover;
        }
      }
    }
    .remove{
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-10px, 10px);
    }
  }
}