.date-range-picker-container{
  .date-ranger-filter{
    display: flex;
    input{
      border: 1px solid #ebebeb;
      border-radius: 4px;
      padding: 8px 7px;
      color: #333333;
    }
    .division{
      width: 7px;
    }
  }

  .input-wrap{
    position: relative;
    width: 49%;
    input{
      cursor: pointer;
      width: 100%;
    }
    >i{
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      font-size: 16px;
    }
  }

}
.picker-panel{
  position: fixed;
  z-index: 999999;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  background: #FFF;
  padding: 10px;
}

