.k-editor{
  width: 100%;
}

.tox {
  .tox-statusbar__help-text{
    visibility: hidden;
  }
  &.tox-tinymce-aux {
    .tox-notifications-container {
      display: none;
    }
  }
  .tox-statusbar__branding{
    display: none;
  }
}

.k-editor-image-dialog{
  .kdialog-body{
    //width: 600px;
  }
}