@import '~bootstrap/scss/bootstrap.scss';
@import 'core/assets/scss/button.scss';
html,
body{
    font-size: 13px;
    min-height: 100vh;
    display: unset !important;
}

@import 'core/assets/scss/app.scss';
